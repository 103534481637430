@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

@font-face {
  font-family: 'Lato-Regular';
  src: url('assets/font/Lato-Regular/Lato-Regular.eot');
  src: url('assets/font/Lato-Regular/Lato-Regular.woff') format('woff'),
  url("assets/font/Lato-Regular/Lato-Regular.ttf") format('truetype'),
  url('assets/font/Lato-Regular/Lato-Regular.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'Roboto';
  src: url("assets/font/Roboto/Roboto-Regular.ttf") format('truetype')
}




@font-face {
  font-family: 'Almoni';
  src: url('assets/font/Almoni/almoni-dl-aaa-regular.otf');
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('assets/font/Lato-Bold/Lato-Bold.eot');
  src: url('assets/font/Lato-Bold/Lato-Bold.woff') format('woff'),
  url("assets/font/Lato-Bold/Lato-Bold.ttf") format('truetype'),
  url('assets/font/Lato-Bold/Lato-Bold.svg#svgFontName') format('svg');
}

@font-face {
  font-family: 'NovemberHebrew-Regular';
  src: url('assets/font/NovemberHebrew/NovemberHebrew-Regular.woff2');
}

@font-face {
  font-family: 'NovemberHebrew-Bold';
  src: url('assets/font/NovemberHebrew/NovemberHebrew-Bold.woff2');
}

@import '~antd/dist/antd.css';
@font-face {
  font-family: 'NovemberHebrew-Light';
  src: url('assets/font/NovemberHebrew/NovemberHebrew-Light.woff2');
}

@font-face {
  font-family: 'Assistant-SemiBold';
  src: url('assets/font/Assistant-SemiBold/Assistant-SemiBold.woff2');
}

@font-face {
  font-family: 'Assistant';
  src: url('assets/font/Assistan/Assistant-Regular.otf');
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.dragging * {
  cursor: grabbing !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
iframe {
  border:none;
}

* {
  outline: none !important;
}

div.bp3-select-popover .bp3-popover-content {
  padding: 0;
}

span.bp3-popover-target {
  display: block;
}

div.bp3-popover-arrow {
  display: none;
}

.bp3-button.bp3-loading {
  cursor: default !important;
}

.display-none {
  display: none;
}

.Toastify__toast {
  padding: 0 !important;
  border-radius: 4px !important;
}

.Toastify__toast-body {
  margin: 0 !important;
}


.lm_goldenlayout .lm_content {
  background: #EBEFF2;
  border: none;
}

.Toastify__progress-bar--default {
  background: #29CCA3 !important;
}

.custom_item_transformer:hover {
  background-color: #404040;
}

.input_white_to_verify_token {
  background: white !important;
  border: 1px solid #d3dee3 !important;
  border-radius: 4px !important;
  color: #768896 !important;
  width: 100% !important;
}

ui5-side-navigation {
  display: none;
}
.CUSTOM_OPEN_ICON{
  width: 90%;
  display: block;
  margin: auto;
  cursor: pointer;
  max-height: 32px;
  object-fit: contain;;
}
ui5-shellbar {
  display: none;
}

.CUSTOM_ACCORDION_WIDGET .bp3-button-text {
  width: 100%;
}

@font-face {
  font-family: "SAP-icons";
  src: url("https://sap.github.io/fundamental-react/theming-base-content/content/Base/baseLib/sap_fiori_3/fonts/SAP-icons.woff") format("woff"); /* available in woff, woff2 and ttf */
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "72";
  src: url("https://sap.github.io/fundamental-react/theming-base-content/content/Base/baseLib/sap_base_fiori/fonts/72-Regular.woff") format("woff"); /* Bold, Light, Regular available in woff and woff2 */
  font-weight: normal;
  font-style: normal;
}
